<template>
  <el-container id="header">
    <el-header >
      <div class="header">
        <div>
          <el-image class="loggo" :src="form.logo_img" fit="fill"></el-image>
        </div>
        <div class="floatRight" v-if="screenWidth <= 600">
          <div :class="{ active: activeNav === 'home' }" @click="change('/home')">
            首页
          </div>
          <div @click="drawer = true" :class="{ active: activeNav !== 'home' }">
            目录
          </div>
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'home' }" @click="change('/home')">
          首页
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'programme' }" @click="change('/programme')">
          智慧酒店方案
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'product' }" @click="change('/product')">
          智慧产品
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'case' }" @click="change('/case')">
          智慧案例
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'news' }" @click="change('/news')">
          新闻资讯
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'about' }" @click="change('/about')">
          关于我们
        </div>
        <div v-if="screenWidth > 600" :class="{ active: activeNav === 'help' }" @click="change('/help')">
          帮助与支持
        </div>
      </div>
    </el-header>
    <el-main id="main">
      <router-view>
        </router-view>
        <el-footer>
      <div class="footer">
        <div class="item">
          <h3>合作加盟</h3>
          <p style="cursor: pointer" @click="toPage('home')">酒店升级</p>
          <p style="cursor: pointer" @click="toPage('home')">渠道合作</p>
        </div>
        <div class="item">
          <h3>关于我们</h3>
          <p style="cursor: pointer" @click="toPage('about', 1)">公司简介</p>
          <p style="cursor: pointer" @click="toPage('about', 2)">联系方式</p>
        </div>
        <div class="item">
          <h3>产品及解决方案</h3>
          <p style="cursor: pointer" v-for="item in type" :key="item.id" @click="toPage('product', item.id)">
            {{ item.title }}
          </p>
          <p style="cursor: pointer" @click="toPage('programme')">
            智慧酒店解决方案
          </p>
        </div>
        <div class="item itemLX">
          <h2>热线服务</h2>
          <div style="">{{ form.tel }}</div>
          <p>服务时间：{{ form.time }}</p>
          <p>企业邮箱：{{ form.email }}</p>
          <p class="address">公司地址：{{ form.address }}</p>
        </div>
        <div class="imgs">
          <div class="img">
            <el-image class="elimage" :src="form.kefu" fit="fill"></el-image>
            <p>微信客服</p>
          </div>
          <div class="img">
            <el-image class="elimage" :src="form.weixin" fit="fill"></el-image>
            <p>官方微信</p>
          </div>
        </div>
      </div>
      <div class="beian">
        {{ form.beian }}
      </div>
    </el-footer>
    <!-- 目录抽屉 -->
    <div class="shouye" v-if="drawer" @click="drawer = false">
      <div class="box_drawer" @click.stop="1">
        <div :class="{ active: activeNav === 'programme' }" @click="change('/programme')">
          智慧酒店方案
        </div>
        <div :class="{ active: activeNav === 'product' }" @click="change('/product')">
          智慧产品
        </div>
        <div :class="{ active: activeNav === 'case' }" @click="change('/case')">
          智慧案例
        </div>
        <div :class="{ active: activeNav === 'news' }" @click="change('/news')">
          新闻资讯
        </div>
        <div :class="{ active: activeNav === 'about' }" @click="change('/about')">
          关于我们
        </div>
        <div :class="{ active: activeNav === 'help' }" @click="change('/help')">
          帮助与支持
        </div>
      </div>
    </div>
    </el-main>
    
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      isActive: 1,
      form: {},
      type: [],
      drawer: false,
      screenWidth: document.documentElement.clientWidth,
      
      // type:this.$route.params.type
    };
  },
  computed: {
    activeNav() {
      return this.$route.name;
    },
  },
  mounted() {
    this.init();
    this.product_category();
    
    window.addEventListener("resize", () => {
      this.screenWidth = document.documentElement.clientWidth;
      this.onscroll()
    });
    document.onscroll = this.onscroll()
  },
  watch:{
    $route:{
      handler(val,oldval){
        this.elmainToTop()
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    init() {
      this.$api.index_config().then((res) => {
        if (res.data.status) {
          this.form = res.data.data;
          document.title = res.data.data.site_name;
          // document.link.href = res.data.data.ico_img;
          let link = document.createElement("link");
          link.rel = "shortcut icon";
          link.href = res.data.data.ico_img;
          document.head.appendChild(link);
          //更改网站关键字
          let meta = document.createElement("meta");
          meta.name = "keywords";
          meta.content = res.data.data.site_keyword;
          console.log("meta", meta);
          document.head.appendChild(meta);
          //更改网站描述
          let desc = document.createElement("meta");
          desc.name = "description";
          desc.content = res.data.data.site_description;
          console.log("desc", desc);
          document.head.appendChild(desc);
          //网站的url
          let url = document.createElement("link");
          url.name = "canonical";
          url.href = res.data.data.site_url;
          document.head.appendChild(url);
        }
      });
    },
    change(url) {
      this.drawer = false;
      this.$router.push(url);
    },
    toPage(url, type) {
      this.$router.replace({ name: url, params: { type } });
    },
    product_category() {
      this.$api.product_category({ page: 1, limit: 10 }).then((res) => {
        if (res.data.status) {
          this.type = res.data.data;
        }
      });
    },
    onscroll(){
      const header = document.getElementById('header')
      const widthRatio = Number(document.documentElement.clientWidth / 1920)
      const height = Number(document.documentElement.clientHeight)
      header.style.height = (height/widthRatio) + 'px';
    },
    //main el-main回到顶部
    elmainToTop(){
      const main = document.getElementById('main');
      main.scrollTop = 0
    }
  },
};
</script>

<style lang="scss" scoped>
.el-main {
  padding: 0;
  width: 1920px;
  overflow-x: hidden;  
  overflow-y: auto;
  // position: absolute;
  // top: 100px;
}

.el-container {
  // overflow: hidden;
  // height: 100%;
}

.el-header {
  position: sticky;
  top: 1px;
  width: 1920px;
  padding: 0;
  height: 100px !important;
  z-index: 99;
  background-color: #fff;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    height: 100px;
    background-color: #fff;
  }

  .loggo {
    width: 168px;
    height: 70px;
  }

  .active {
    color: rgba(0, 90, 247, 1);
  }

  >div {

    // margin-right: 82px;
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }

    color: rgba(16, 16, 16, 1);
    font-size: 18px;
    white-space: nowrap;
    cursor: pointer;
  }

  @media screen and (max-width: 600px) {
    justify-content: space-between;
    height: 200px !important;

    div {
      margin-left: 40px;
      margin-right: 40px;

      .loggo {
        width: 336px;
        height: 140px;
      }
    }

    .floatRight {
      display: flex;
      align-items: center;
      margin-left: 1200px;

      div {
        font-size: 36px;
        margin-left: 40px;
      }
    }

    ::videep .el-drawer {
      position: absolute;
      right: 0;
    }

    ::v-deep .el-drawer__wrapper {
      width: 1920px;

      .el-drawer__container {
        padding-left: 1120;
        width: 800px;
      }
    }
  }
}

.el-footer {
  width: 1920px;
  padding: 0;
  min-height: 362px;

  .footer {
    min-height: 300px;
    display: flex;
    justify-content: center;
    background-color: rgba(245, 245, 245, 1);
    box-sizing: content-box;
    flex-wrap: wrap;

    .item {
      text-align: left;
      color: rgba(16, 16, 16, 1);
      font-size: 18px;
      margin-right: 73px;
      padding-top: 48px;

      h3 {
        color: rgba(16, 16, 16, 1);
        font-size: 21px;
        height: 33px;
        line-height: 33px;
        margin-bottom: 25px;
      }

      p {
        height: 29px;
        line-height: 29px;
        margin-bottom: 6px;
      }

      h2 {
        color: rgba(16, 16, 16, 1);
        font-size: 21px;
        margin-bottom: 2px;
      }

      div {
        height: 58px;
        line-height: 58px;
        color: rgba(16, 16, 16, 1);
        font-size: 38px;
      }

      .address {
        word-break: break-all;
        width: 380px;
      }
    }

    .itemLX {
      div {
        margin-bottom: 4px;
      }

      p {
        margin-bottom: 5px;
      }
    }

    .imgs {
      display: flex;
      padding-top: 73px;

      .img {
        margin-right: 22px;

        .elimage {
          width: 150px;
          height: 150px;
        }
      }

      p {
        height: 26px;
        line-height: 26px;
        color: rgba(16, 16, 16, 1);
        font-size: 16px;
        margin-top: 13px;
      }
    }
  }

  .beian {
    height: 62px;
    line-height: 62px;
    background-color: rgba(16, 16, 16, 1);
    text-align: center;
    color: rgba(255, 255, 255, 1);
  }
}

.shouye {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 100%;
  z-index: 99;
  background-color: rgba(25, 25, 25, 0.3);
  text-align: right;

  .box_drawer {
    padding-top: 150px;
    background-color: #fff;
    display: inline-block;
    height: 100%;
    width: 70%;

    .active {
      color: rgba(0, 90, 247, 1);
    }

    div {
      text-align: center;
      margin: 20px auto;
      font-size: 48px;
    }
  }
}

@media screen and (max-width: 600px) {
  .el-footer {
    width: 1920px;
    padding: 0;
    min-height: 362px;

    .footer {
      min-height: 600px;
      display: flex;
      justify-content: center;
      background-color: rgba(245, 245, 245, 1);
      box-sizing: content-box;
      flex-wrap: wrap;

      .item {
        text-align: left;
        color: rgba(16, 16, 16, 1);
        font-size: 40px;
        margin-right: 146px;
        padding-top: 96px;

        h3 {
          color: rgba(16, 16, 16, 1);
          font-size: 46px;
          height: 66px;
          line-height: 66px;
          margin-bottom: 50px;
        }

        p {
          height: 60px;
          line-height: 60px;
          margin-bottom: 12px;
        }

        h2 {
          color: rgba(16, 16, 16, 1);
          font-size: 46px;
          margin-bottom: 4px;
        }

        div {
          height: 112px;
          line-height: 112px;
          color: rgba(16, 16, 16, 1);
          font-size: 80px;
        }

        .address {
          word-break: break-all;
          width: 760px;
        }
      }

      .itemLX {
        div {
          margin-bottom: 4px;
        }

        p {
          margin-bottom: 5px;
        }
      }

      .imgs {
        display: flex;
        padding-top: 146px;

        .img {
          margin-right: 44px;

          .elimage {
            width: 300px;
            height: 300px;
          }
        }

        p {
          height: 52px;
          line-height: 52px;
          color: rgba(16, 16, 16, 1);
          font-size: 36px;
          margin-top: 26px;
        }
      }
    }

    .beian {
      height: 124px;
      line-height: 124px;
      background-color: rgba(16, 16, 16, 1);
      text-align: center;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>